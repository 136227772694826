<template>
  <div class="grid">

    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-1">
      		    <div class="col-12 section_title">ORDENES DE PRODUCCIÓN </div>
            </div>
          </template>

          <template v-slot:end>

              <span class="block mt-2 md:mt-0 p-input-icon-left mr-3">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Buscar..."
                />
              </span>

              <Button
                label="NUEVA ORDEN"
                icon="pi pi-plus"
                class="p-button-info mr-2"
                @click="this.$router.push('/produccion/crear')"
              />
          </template>
        </Toolbar>

    <!-- <div class="col-12">
      <div class="card">
          <Button type="button" label="CUMPLIDO" icon="pi pi-check" class="p-button-success ml-2" :badge="this.countCumplid" badgeClass="p-badge-success text-xl" />
          <Button type="button" label="PRONTA ENTREGA" icon="pi pi-info-circle
" class="p-button-warning ml-2" :badge="this.countPronta" badgeClass="p-badge-warning text-xl" />
          <Button type="button" label="DEMORADO " icon="pi pi-times-circle" class="p-button-danger ml-2" :badge="this.countDemorado" badgeClass="p-badge-danger text-xl" />
      </div>  
    </div> -->
        <DataTable
          ref="dt"
          :value="products"
          dataKey="id"
          :paginator="true"
          :rows="10"
          :filters="filters"
          :rowClass="rowClass"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} pedidos"
          responsiveLayout="scroll"
        >
 

          <Column
            field="id"
            header="N° OP"
            :sortable="true"
            headerStyle="min-width:5rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">N° OP</span>
              {{ slotProps.data.id }}
            </template>
        </Column>

        <Column
            field="client"
            header="Cliente"
            :sortable="true"
            headerStyle="min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Cliente</span>
              {{ giveMeName(slotProps.data.client) }}
            </template>
          </Column>
        <Column
            field="oc"
            header="O.C."
            :sortable="true"
            headerStyle="min-width:3rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">O.C.</span>
              {{ slotProps.data.oc }}
            </template>
          </Column>

        <Column
            field="detail"
            header="Detalle"
            :sortable="true"
            headerStyle="min-width:10rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Detalle</span>
              {{ slotProps.data.obs }}
            </template>
          </Column>
                  <Column
            field="datein"
            header="Ingreso"
            :sortable="true"
            headerStyle="min-width:3rem;"
          >
            <template #body="slotProps">
              <span class="p-column-title">Ingreso</span>
              {{ formatDate(slotProps.data.datein) }}
            </template>
          </Column>
          <Column
                field="dateout"
                header="Entrega"
                :sortable="true"
                headerStyle="min-width:3rem;"
                >
                <template #body="slotProps">
                    <span class="p-column-title">Entrega</span>
                    {{ formatDate(slotProps.data.dateout) }}
                </template> 
            </Column>

            <Column
                field="currency"
                header="Moneda"
                :sortable="true"
                headerStyle="min-width:3rem;"
                >
                <template #body="slotProps">
                    <span class="p-column-title">Moneda</span>
                    {{ currencyName(slotProps.data.currency) }}
                </template>
            </Column>
            <Column field="cdolarpeso" header="Total" :sortable="true" headerStyle="min-width:3rem;">
              <template #body="slotProps">
                <span class="p-column-title">Total</span>
                {{ formatCurrency(slotProps.data.cdolarpeso) }}
              </template>
            </Column>
            <!-- <Column
                field="status"
                header="Estado"
                :sortable="true"
                headerStyle="min-width:5rem;"
                >
                <template #body="slotProps">
                    <span class="p-column-title">Estado</span>
                    <div :class="'status_box status-' + slotProps.data.status">{{ statusName(slotProps.data.status) }}</div>
                </template>
            </Column>
             -->
          
          <Column headerStyle="min-width:4rem;">
            <template #body="slotProps">
              <Button
                icon="pi pi-cog"
                class="p-button-rounded p-button-primary mr-2"
                @click="this.$router.push('/produccion/' + slotProps.data.id)"
              />
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              /> 
            </template>
          </Column>
        </DataTable>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirmación"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Eliminaras a <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteProduct"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product">Seguro?</span>
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Sí"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import CrudService from "../../services/crud.service";

export default {
  data() {
    return {
      countDemorado: 20,
      countPronta:  40,
      countCumplid: 10,
      routeName: null,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      selectedProducts: null,
      filters: {},
      submitted: false,
      validationErrors: null,
      providers: []
    };
  },
   
  created() {
    // get route name
    this.routeName = this.$route.name;
     
    this.initFilters();
  },
  mounted() {
    CrudService.getCRUD('produccion').then(
      (data) => (this.products = data)
    );
    CrudService.getCRUD('cliente').then(
      (data) => (this.providers = data)
    );

  },
  methods: {
    giveMeClass(data){

      let today = new Date();
      let dateout = new Date(data.dateout);

      // diff in days between today and dateout
      let diff = Math.floor(
        (Date.UTC(today.getFullYear(), today.getMonth(), today.getDate()) -
          Date.UTC(
            dateout.getFullYear(),
            dateout.getMonth(),
            dateout.getDate()
          )) /
          (1000 * 60 * 60 * 24)
      );
      //check if diff is less than 7 days
      if (diff >= 7 && data.status !== 3) {
        //console.log(data)
        this.countDemorado++;
        return "rowbad";
      }
      
      //check if diff is less than 14 days
      if (diff > -7 && diff < 0 && data.status !== 3) {
        this.countPronta++;
        return "rowwarn";
      }

      //check if diff is less than 14 days
      if (diff < 0 && data.status == 3) {
        this.countCumplid++;
        return "rowgood";
      }

      return null;
    },
    rowClass(data) {

      switch(data.status){
        case 2:
          return "rowbad";
        case 3:
          return "rowwarn";
        case 4:
          return "rowgood";
        default:
          return null;
      }
      //console.log(data.id)
      //return this.giveMeClass(data)

    },
    giveMeName(id) {
      let exist = this.providers.find(provider => provider.id === id);

      if(!exist) {
        return ' - ';
      }else {
        return exist.name;
      }
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    formatDate(value){
      // format date to dd-mm-yyyy
      if (value) {
        let date = new Date(value);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        return day.toString().padStart(2, '0') + '/' + month.toString().padStart(2, '0') + '/' + year;
      }
      
    },
    currencyName(value){

      switch (value) {
        case 1:
          return 'Dolar';
          break;
        case 0:
          return 'Peso';
          break;
        case 2:
          return 'Euro';
          break;
        default:
          return 'Otro';
          break;
        }
    },

    statusName(value){

      switch (value) {

        case 0:
          return 'Sin iniciar';
          break;
        case 1:
          return 'En proceso';
          break;
        case 2:
          return 'En calidad';
          break;
        case 3:
          return 'Finalizada';
          break;
        default:
          return 'Otro';
          break;
        }
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
        displayErrors(errors) {
      
              for (let key in errors) {

                this.validationErrors.push({ "field": key, "message": errors[key] });

                let error = errors[key];
                this.$toast.add({
                  severity: "error",
                  summary: "Error!",
                  detail: error[0],
                  life: 3000,
                });
              }
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          CrudService.updateCRUD(this.routeName, this.routeName, this.product).then(
            (data) => {

            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);

            } else {
              this.$toast.add({
                severity: "success",
                summary: "Éxito!",
                detail: "Actualizado correctamente.",
                life: 3000,
              });
              this.products[this.findIndexById(this.product.id)] = this.product;
              this.productDialog = false;
              this.product = {};
            }
          });
        } else {
          CrudService.createCRUD(this.routeName, this.routeName, this.product).then(
            (data) => {
              
            this.validationErrors = [];
            if (data.status == 400) { 

              this.displayErrors(data.data);
              
              } else {
                this.products.push(this.product);
                this.$toast.add({
                  severity: "success",
                  summary: "Éxito!",
                  detail: "Creado correctamente.",
                  life: 3000,
                });
                this.productDialog = false;
                this.product = {};
              }
            }
          );
        }
      }

    },
    editProduct(product) {
      //this.product = { ...product };
      //this.productDialog = true;

      //alert('en desarrollo..')
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      
      CrudService.deleteCRUD(this.routeName, this.routeName, this.product.id).then(
        (data) => {

          this.products = this.products.filter((val) => val.id !== this.product.id);

          this.$toast.add({
            severity: "success",
            summary: "Éxito!",
            detail: "Eliminado correctamente.",
            life: 3000,
          });
        }
      );

      this.deleteProductDialog = false;
      this.product = {};
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Éxito!",
        detail: "Eliminado correctamente.",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
};
</script>

<style scoped>
        .status_box {

          display: inline-block;
          width: 95px;
          font-weight: bolder;
          text-align: center;
          padding: 5px;
          text-transform: uppercase;
        }
        .status-3 {
            border: 1px solid #44b73a71;
            background-color: #44b73a71;
        }
        .status-2 {
            border: 1px solid #ff99005b;
            background-color: #ff99005b;
        }
        .status-1 {
            border: 1px solid #1c96c256;
            background-color: #1c96c256;
          
        }
        .status-0 {
            border: 1px solid #d8d8d89a;
            background-color: #d8d8d89a;
        }
</style>
